<template>
 <div class="content">
   <app-cells position="between">
     <h1 class="title title--theme">Статистика: Участники</h1>
     <v-select
       @search="onFilterSearch"
       noDrop
       label="id"
       placeholder="Поиск по названию"
       class="select select--minw"
     />
   </app-cells>
   <app-cells position="start">
     <app-checkbox
       v-model="participants_done"
       @change="fetchItems()"
       label="Сформированы"
     />
     <app-checkbox
       v-model="participants_print"
       @change="fetchItems()"
       label="Проверены"
     />
   </app-cells>
   <v-client-table
     @row-click="onRowClick"
     :data="table.items"
     :columns="table.columns"
     :options="table.options"
     ref="tableDefault"
     class="table-default table-default--dynamic"
   >
     <div slot="delegation_name" slot-scope="props" class="table-default__left">
       {{ props.row.delegation_name }}
     </div>
     <template #participants_done="props">
       {{ props.row.participants_done ? 'Да' : 'Нет' }}
     </template>
     <template #participants_print="props">
       {{ props.row.participants_print ? 'Да' : 'Нет' }}
     </template>
     <template #accept="props">
       <span class="grey">Черновики: {{ props.row.draft }}</span>&nbsp;
       <span class="red">Отклонены: {{ props.row.reject }}</span><br>
       <span class="yellow">На рассмотрении: {{ props.row.review }}</span>&nbsp;
       <span class="green">Приняты: {{ props.row.accept }}</span>
     </template>
   </v-client-table>
 </div>
</template>

<script>
import { getMemberStat } from '@/http'
import { debounce } from 'lodash'

export default {
  name: 'MembersStat',
  data() {
    return {
      table: {
        items: [],
        columns: ['delegation_name', 'all', 'participants_done', 'participants_print', 'accept'],
        options: {
          headings: {
            delegation_name: 'Делегация',
            all: 'Всего',
            participants_done: 'Сформирована',
            participants_print: 'Проверена',
            accept: 'Статусы участников',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 1000,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Делегации отсутствуют',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row ${row.participants_done ? 'table-default__row--review' : 'table-default__row--draft'} ${row.participants_done && row.participants_print && 'table-default__row--accept'}`,
        },
      },
      participants_done: false,
      participants_print: false,
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems(delegation_name = '', participants_done = this.participants_done, participants_print = this.participants_print) {
      getMemberStat({delegation_name, participants_done, participants_print})
        .then(response => {
          this.table.items = response.data
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'members-list', params: { delegation_id: row.row.delegation_id } })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(search)
      loading(false)
    }, 350),
  }
}
</script>

